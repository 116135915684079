var render = function render(){var _vm=this,_c=_vm._self._c;return _c('section',[_c('a-form',{staticClass:"form-container",attrs:{"form":_vm.form}},[_c('section',{staticClass:"form-item"},[_c('p',{staticClass:"title"},[_vm._v("人员信息")]),_c('a-row',[_c('a-col',{staticStyle:{"width":"1080px"}},[_c('a-form-item',{attrs:{"label-col":{ span: 2 },"wrapper-col":{ span: 20 }}},[_c('span',{attrs:{"slot":"label"},slot:"label"},[_vm._v("人员信息")]),_c('section',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                'personSimpleInfos',
                { rules: [{ required: true, message: '请选择人员' }] },
              ]),expression:"[\n                'personSimpleInfos',\n                { rules: [{ required: true, message: '请选择人员' }] },\n              ]"}],staticClass:"person-list-container"},[_c('a-button',{staticClass:"action-btn",on:{"click":_vm.showSelectPerson}},[_c('a-icon',{attrs:{"type":"setting"}}),_vm._v("选择人员")],1)],1)])],1)],1),_c('section',{staticClass:"list-table-container"},[_c('a-table',{staticClass:"m-t-18",attrs:{"bordered":false,"size":"small","columns":_vm.columns,"data-source":_vm.personnelList,"pagination":false},scopedSlots:_vm._u([{key:"action",fn:function(list){return _c('a',{},[_c('a-popconfirm',{attrs:{"title":"确定删除?"},on:{"confirm":function($event){return _vm.deleteItem(list)}}},[_vm._v("删除")])],1)}}])})],1)],1),_c('section',{staticClass:"form-item"},[_c('p',{staticClass:"title"},[_vm._v("拉黑说明")]),_c('a-row',[_c('a-col',{attrs:{"span":8}},[_c('a-form-item',{attrs:{"label":"有效期限"}},[_c('div',{staticStyle:{"display":"flex","align-items":"center"}},[_c('a-date-picker',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                  'validityDate',
                  {
                    rules: [
                      {
                        required: !this.setValueStatus,
                        message: '请选择时间',
                      },
                    ],
                  },
                ]),expression:"[\n                  'validityDate',\n                  {\n                    rules: [\n                      {\n                        required: !this.setValueStatus,\n                        message: '请选择时间',\n                      },\n                    ],\n                  },\n                ]"}],attrs:{"placeholder":"请选择","disabled":this.setValueStatus,"disabled-date":_vm.disabledDate}}),_c('a-checkbox',{staticStyle:{"min-width":"60px","margin-left":"6px"},attrs:{"checked":_vm.setValueStatus},on:{"change":_vm.changqiCg}},[_vm._v("永久")])],1)])],1)],1),_c('a-row',[_c('a-col',{attrs:{"span":24}},[_c('a-form-item',{attrs:{"label":"拉黑说明"}},[_c('a-textarea',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                'controlDesc',
                {
                  rules: [
                    { required: false, message: '请输入拉黑说明' },
                    { max: 150, message: '最大输入150个字符' },
                  ],
                },
              ]),expression:"[\n                'controlDesc',\n                {\n                  rules: [\n                    { required: false, message: '请输入拉黑说明' },\n                    { max: 150, message: '最大输入150个字符' },\n                  ],\n                },\n              ]"}],attrs:{"rows":"4","placeholder":"请输入"}})],1)],1)],1)],1),_c('section',{staticClass:"form-item m-t-10"},[_c('div',{staticClass:"form-btn-group",staticStyle:{"text-align":"center","padding":"18px 0"}},[_c('a-button',{attrs:{"disabled":_vm.submitLoadding,"loading":_vm.submitLoadding},on:{"click":_vm.submit}},[(!_vm.submitLoadding)?_c('a-icon',{attrs:{"type":"save"}}):_vm._e(),_vm._v("保存 ")],1),_c('a-button',{on:{"click":function($event){return _vm.$router.back()}}},[_vm._v("取消")])],1)])]),_c('a-modal',{staticClass:"modal-container",attrs:{"width":"1080px","title":"人员列表","footer":false},model:{value:(_vm.visible),callback:function ($$v) {_vm.visible=$$v},expression:"visible"}},[_c('selectPerson',{on:{"ok":_vm.getSelectPerson,"cancel":_vm.cancelSelect}})],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }