<template>
  <section>
    <a-form class="form-container" :form="form">
      <section class="form-item">
        <p class="title">人员信息</p>
        <a-row>
          <a-col style="width: 1080px">
            <a-form-item :label-col="{ span: 2 }" :wrapper-col="{ span: 20 }">
              <span slot="label">人员信息</span>
              <section
                class="person-list-container"
                v-decorator="[
                  'personSimpleInfos',
                  { rules: [{ required: true, message: '请选择人员' }] },
                ]"
              >
                <a-button class="action-btn" @click="showSelectPerson">
                  <a-icon type="setting" />选择人员</a-button
                >
                <!-- <a-row class="person-list m-t-10" v-if="personnelList.length">
                  <a-row type="flex" :gutter="10">
                    <a-col
                      :span="6"
                      v-for="(item, key) in personnelList"
                      :key="key"
                    >
                      <a-row class="list-item">
                        <div class="m-b-8">{{ item.personnelName }}</div>
                        <span class="desc">{{ item.jobName }}</span>
                        <a class="close" @click="deleteItem(item)"
                          ><a-icon title="删除" type="close"></a-icon
                        ></a>
                      </a-row>
                    </a-col>
                  </a-row>
                </a-row> -->
              </section>
            </a-form-item>
          </a-col>
        </a-row>
        <section class="list-table-container">
          <a-table
            :bordered="false"
            size="small"
            class="m-t-18"
            :columns="columns"
            :data-source="personnelList"
            :pagination="false"
          >
            <a slot="action" slot-scope="list">
              <a-popconfirm title="确定删除?" @confirm="deleteItem(list)"
                >删除</a-popconfirm
              >
            </a>
          </a-table>
        </section>
      </section>
      <section class="form-item">
        <p class="title">拉黑说明</p>
        <a-row>
          <a-col :span="8">
            <a-form-item label="有效期限">
              <div style="display: flex; align-items: center">
                <a-date-picker
                  placeholder="请选择"
                  :disabled = this.setValueStatus
                  :disabled-date="disabledDate"
                  v-decorator="[
                    'validityDate',
                    {
                      rules: [
                        {
                          required: !this.setValueStatus,
                          message: '请选择时间',
                        },
                      ],
                    },
                  ]"
                />
                <a-checkbox
                  :checked="setValueStatus"
                  @change="changqiCg"
                  style="min-width: 60px; margin-left: 6px"
                  >永久</a-checkbox
                >
              </div>
            </a-form-item>
          </a-col>
        </a-row>
        <a-row>
          <a-col :span="24">
            <a-form-item label="拉黑说明">
              <a-textarea
                rows="4"
                placeholder="请输入"
                v-decorator="[
                  'controlDesc',
                  {
                    rules: [
                      { required: false, message: '请输入拉黑说明' },
                      { max: 150, message: '最大输入150个字符' },
                    ],
                  },
                ]"
              ></a-textarea>
            </a-form-item>
          </a-col>
        </a-row>
      </section>

      <section class="form-item m-t-10">
        <div class="form-btn-group" style="text-align: center; padding: 18px 0">
          <a-button
            @click="submit"
            :disabled="submitLoadding"
            :loading="submitLoadding"
          >
            <a-icon type="save" v-if="!submitLoadding" />保存
          </a-button>
          <a-button @click="$router.back()">取消</a-button>
        </div>
      </section>
    </a-form>

    <a-modal
      width="1080px"
      class="modal-container"
      v-model="visible"
      title="人员列表"
      :footer="false"
    >
      <selectPerson @ok="getSelectPerson" @cancel="cancelSelect" />
    </a-modal>
  </section>
</template>

<script>
const columns = [
  { title: "序号", dataIndex: "key", align: "center", key: "key" },
  {
    title: "单位名称",
    dataIndex: "companyName",
    align: "center",
    key: "companyName",
  },
  {
    title: "职位/工种",
    dataIndex: "jobName",
    align: "center",
    key: "jobName",
  },
  {
    title: "班组名称",
    dataIndex: "team_name",
    align: "center",
    key: "team_name",
  },
  {
    title: "姓名",
    dataIndex: "personnelName",
    align: "center",
    key: "personnelName",
  },
  {
    title: "联系方式",
    dataIndex: "phoneNumber",
    align: "center",
    key: "fileNaphoneNumberme",
  },
  { title: "登记时间", dataIndex: "inDate", align: "center", key: "inDate" },
  {
    title: "操作",
    align: "center",
    key: "action",
    scopedSlots: { customRender: "action" },
  },
];
import moment from "moment";
import selectPerson from "@/components/selectPerson";
export default {
  data() {
    return {
      columns,
      personnelList: [],
      visible: false,
      setValueStatus: false,
    };
  },
  components: { selectPerson },
  beforeCreate() {
    this.form = this.$form.createForm(this);
  },
  computed: {
    did() {
      return this.$route.query.id;
    },
    submitLoadding() {
      return this.$store.state.submitLoadding;
    },
  },
  methods: {
    disabledDate(current) {
				// return current && current <= moment().endOf('day')
        return current && current < moment().subtract(1, 'day'); 
			},
    changqiCg(value) {
      this.setValueStatus = value.target.checked;

      this.$nextTick(() => {
        this.form.setFieldsValue({
          validityDate: "",
        });
      });
    },
    showSelectPerson() {
      this.visible = true;
    },
    getSelectPerson(list) {
      (this.visible = false), (this.personnelList = [...list]);
      let personSimpleInfos = this.personnelList.map((item) => {
        return {
          personName: item.personnelName,
          personId: item.id,
        };
      });
      this.personnelList.forEach((item, ind) => {
        item.key = ind + 1;
        // item.inDate =
        //   item.inDate != "" ? moment(item.inDate).format("YYYY-MM-DD") : "-";
        item.companyName = item.companyName ? item.companyName : "-";
        item.teamName = item.teamName ? item.teamName : "-";
        item.jobName = item.jobName ? item.jobName : "-";
      });
      this.$nextTick(() => {
        this.form.setFieldsValue({
          personSimpleInfos: this.personnelList.length ? personSimpleInfos : "",
        });
      });
    },
    cancelSelect() {
      this.visible = false;
      this.personnelList = [];
      this.$nextTick(() => {
        this.form.setFieldsValue({
          personSimpleInfos: "",
        });
      });
    },
    deleteItem(list) {
      this.personnelList = this.personnelList.filter(
        (item) => item.id != list.id
      );
      let personSimpleInfos = this.personnelList.map((item) => {
        return {
          personName: item.personnelName,
          personId: item.id,
        };
      });
      this.$nextTick(() => {
        this.form.setFieldsValue({
          personSimpleInfos: this.personnelList.length ? personSimpleInfos : "",
        });
      });
    },
    batchUnBindFaceDevice(info) {
      this.$api.batchUnBindFaceDevice(info).then(() => {});
    },
    submit() {
      this.form.validateFields((err, formData) => {
        if (!err) {
          let personnelArr = [];
           formData.personSimpleInfos.forEach((item) => {
              personnelArr.push(item.personId);
            });
            if (this.setValueStatus == true) {
              formData.validityDate = "-1";
            } else {
              formData.validityDate = formData.validityDate
                ? formData.validityDate.format("YYYY-MM-DD")
                : null;
            }
          let param = {
            personnelIds: personnelArr,
            validityDate: formData.validityDate,
            controlDesc: formData.controlDesc,
          };
          let perInfo = {
            ids: personnelArr,
          };
          this.$store.commit("SET_SUBMITLOADDING", true);

          // let apiName = this.did ? "updateDutyApply" : "insertBlack";
          this.$api.insertBlack(param).then((res) => {
            if (res.code === 200) {
              this.batchUnBindFaceDevice(perInfo);
              this.$router.back();
            } else {
              this.$message.error(res.msg);
            }
          });
        }
      });
    },
  },
};
</script>


<style lang="scss" scoped>
.person-list-container {
  .action-btn {
    background: #3f72ba;
    border: 1px solid #3f72ba;
    color: #ffffff;
  }
  .person-list {
    border: 1px solid rgba(76, 132, 211, 0.1);
    border-radius: 4px;
    padding: 16px;
    .list-item {
      border: 1px solid rgba(76, 132, 211, 0.5);
      padding: 8px 10px 10px 25px;
      border-radius: 4px;
      line-height: 20px;
      color: rgba(255, 255, 255, 0.9);
      position: relative;
      .desc {
        color: #989898;
      }
      &:hover {
        .close {
          display: block;
        }
      }
      .close {
        position: absolute;
        top: -10px;
        right: -10px;
        height: 20px;
        width: 20px;
        border-radius: 100%;
        background: rgba(0, 0, 0, 0.3);
        font-size: 10px;
        line-height: 20px;
        text-align: center;
        color: rgba(255, 255, 255, 1);
        border: 1px solid rgba(255, 255, 255, 0.5);
        z-index: 99;
        display: none;
      }
    }
  }
}
</style>